export const defaultSetting = {
  slideWidth: 280,
  languages: [
    {
      key: 'zh',
      name: 'wrQwwbSV',
    },
    {
      key: 'en',
      name: 'hGtEfNnp',
    },
  ],
  defaultLang: 'en',
}