export default {
  wbTMzvDM: "FOTA Background Management System",
  wVzXBuYs: "Please enter an account",
  RNISycbR: "Account",
  DjMcEMAe: "Please input a password",
  HplkKxdY: "Password",
  dDdqAAve: "Login",
  wrQwwbSV: "Chinese",
  hGtEfNnp: "English",
  jhqxJPbn: "Search Menu",
  Logout: "Logout",
  TOrcokVD: "Total device",
  pTWOlAxb: "Installed device",
  xPLGKZqp: "DAU",
  UJosbpCK: "Install progress",
  sRyBNWmp: "Daily",
  ErHKtuTE: "Monthly",
  GToqMRGr: "Installed",
  yLkZTWbn: "Today",
  QFqMuZiD: "This month",
  lGOcGyrv: "This year",
  yzUIyMhr: "Store sales",
  aSPCUBcK: "Today",
  EhTpnarX: "This month",
  AGGPEAdX: "This year",
  LhjNVSoc: "Name",
  MOlwAEMx: "Age",
  npxxdPKd: "Address",
  YoERuunu: "Occupation",
  QkOmYwne: "Action",
  EOSDTAVT: "Name",
  hQeqcUTv: "Age",
  YHapJMTT: "Search",
  uCkoPyVp: "Clean",
  qYznwlfj: "User Name",
  gohANZwy: "Nickname",
  yBxFprdB: "Phone Number",
  XWVvMWig: "Mailbox",
  ykrQSYRh: "Gender",
  AkkyZTUy: "male",
  yduIcxbx: "female",
  TMuQjpWo: "Creation time",
  qEIlwmxC: "Edit",
  JjwFfqHG: "Warning",
  nlZBTfzL: "Are you sure to delete this data?",
  bvwOSeoJ: "Successfully deleted!",
  HJYhipnp: "Delete",
  rnyigssw: "Nickname",
  SPsRnpyN: "Phone Number",
  morEPEyc: "Add",
  wXpnewYo: "Edit",
  VjwnJLPY: "New",
  NfOSPWDa: "Updated successfully!",
  JANFdKFM: "Created successfully!",
  jwGPaPNq: "Cannot be empty",
  iricpuxB: "Cannot be empty",
  UdKeETRS: "Cannot be empty",
  AnDwfuuT: "Incorrect phone number format",
  QFkffbad: "Cannot be empty",
  EfwYKLsR: "Incorrect email format",
  Dashboard: "Dashboard",
  "OTA Management": "OTA Management",
  "Version Stats": "Version Stats",
  "Device Info": "Device Info",
  "System Management": "System Management",
  HciKVeLm: "Model",
  dPlTSvdD: "Source Version",
  odOEtkpc: "Target Version",
  iqNKQpUY: "Whitelist",
  ilBWxpDY: "Blacklist",
  EECJHiOb: "Force Upgrade",
  tnukMXhH: "Size",
  RpoOXBQc: "Model (Fuzzy Matching)",
  gKJjLuzZ: "Only numbers, letters, and commas can be entered",
  GhIekrta: "Source Version",
  nyQpZIRx:
    "The * means that all devices are allowed to upgrade (blacklist has higher priority). Multiple devices should be separated by commas.",
  pCdmENWl:
    "Even if the whitelist is *, devices on the blacklist will not be upgraded.",
  KItVPsDB: "Force Upgrade",
  SibonDPU:
    "Force Upgrade means that the device does not require user confirmation and will automatically upgrade and reboot the device.",
  qldILawE: "Device ID",
  uKVLxprY: "Model",
  vbywrUeP: "Version",
  pVGgKiCk: "Install Date",
  msetuSsf: "Login Time",
  sopqNxZx: "Model/IMEI/ICCID (Full Matching)",
  nkvLiIsd: "Total",
  UhhJtmtn: "Records",
  DGqCBsXF: "Total",
  YwyNhmIM: "Count",
  SvPwzqNq: "Installed",
  XumaHpZN: "Model (Full Matching)",
  kokkZHDl: "Sorry, the page you visited does not exist.",
  zxeoPXnd: "Home Page",
  MVGSoqNu: "The link is incorrect, please check.",
  tbBLCeGa: "Entered passwords differ!",
  SNLzwHEJ: "Password reset successful",
  ptQlibOB: "FOTA-ADMIN password reset",
  AlLNrWWQ: "Set your new password",
  YOVwbIUF: "Repeat password",
  kRlUmMSZ: "OK",
  SgycTwuo: "Return to login",
  XBJLLdXO: "Invalid email format!",
  KGoWuyii: "The email has been sent, please check your email.",
  VLsbvhOs: "Please enter the verification code",
  VKRphndB: "Verification Code",
  mDBzTAPP: "Forgot Password?",
  wjKXWQgp: "Reset Password",
  zenwQGuP: "Please Enter Your Email Address",
  dashqAmN: "Send Verification Email",
  cCmFugej: "Refresh",
  YNGDGAcJ: "Close",
  XMZaTVqf: "Close Others",
};
