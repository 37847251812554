import { t } from '@/utils/i18n';
export function getParamsBySearchParams<T = any>(query: URLSearchParams) {
  const params = [...query.keys()].reduce<Record<string, any>>(
    (prev, cur: string) => {
      if (cur) {
        prev[cur] = query.get(cur);
      }
      return prev;
    },
    {}
  );
  return params as T;
}

/**
  * 获取第一个表格的可视化高度
  * @param {*} extraHeight 额外的高度(表格底部的内容高度 Number类型,默认为74) 
  * @param {*} id 当前页面中有多个table时需要制定table的id
  */
export const getTableScroll = (params: { extraHeight?: number, id?: string }) => {
  let extraHeight = params?.extraHeight;
  if (typeof extraHeight == "undefined") {
    //  默认底部边距20
    extraHeight = 0;
  }
  let tHeader = null
  if (params?.id) {
    const { id } = params;
    const elem = document.getElementById(id);
    tHeader = elem ? elem.getElementsByClassName("ant-table-thead")[0] : null
  } else {
    tHeader = document.getElementsByClassName("ant-table-thead")[0];
  }
  //表格内容距离顶部的距离
  let tHeaderBottom = 0;
  if (tHeader) {
    tHeaderBottom = tHeader.getBoundingClientRect().bottom;
  }
 
  //窗体高度-表格内容顶部的高度-表格内容底部的高度
  //const height = `calc(100vh - ${tHeaderBottom + extraHeight}px)`;
  const height = document.body.clientHeight - tHeaderBottom - extraHeight
  return height;
}

/**
  * 菜单本地化
  * @param {*} name 从服务器获取到的菜单名称
  */
export const menuLocalize = (name?: string) => {
  return t(name || 'undefined'); 
  // switch(name) { 
  //   case "Dashboard":
  //     return t("Dashboard" /* 仪表盘 */); 
  //   case "OTA Management":
  //     return t("OTA Management" /* OTA管理 */); 
  //   case "Version Stats":
  //     return t("ZxfffsSu" /* 版本统计 */); 
  //   case "Device Info":
  //     return t("vrHqzvPf" /* 设备查询 */); 
  //   case "System Management":
  //     return t("WKFyDAZO" /* 系统管理 */); 
  //   default:
  //     return name;
  // }
}
