export default {
  wbTMzvDM: "FOTA后台管理系统",
  wVzXBuYs: "请输入账号",
  RNISycbR: "账号",
  DjMcEMAe: "请输入密码",
  HplkKxdY: "密码",
  dDdqAAve: "登录",
  wrQwwbSV: "中文",
  hGtEfNnp: "英语",
  jhqxJPbn: "搜索菜单",
  Logout: "退出登录",
  TOrcokVD: "总设备量",
  pTWOlAxb: "已安装量",
  xPLGKZqp: "日活量",
  UJosbpCK: "安装进度",
  sRyBNWmp: "按日",
  ErHKtuTE: "按月",
  GToqMRGr: "已安装",
  yLkZTWbn: "今日",
  QFqMuZiD: "本月",
  lGOcGyrv: "本年",
  aSPCUBcK: "今日",
  EhTpnarX: "本月",
  AGGPEAdX: "本年",
  LhjNVSoc: "名称",
  MOlwAEMx: "年龄",
  npxxdPKd: "地址",
  YoERuunu: "职业",
  QkOmYwne: "操作",
  EOSDTAVT: "名称",
  hQeqcUTv: "年龄",
  YHapJMTT: "搜索",
  uCkoPyVp: "清除",
  qYznwlfj: "用户名",
  gohANZwy: "昵称",
  yBxFprdB: "手机号",
  XWVvMWig: "邮箱",
  ykrQSYRh: "性别",
  AkkyZTUy: "男",
  yduIcxbx: "女",
  TMuQjpWo: "创建时间",
  qEIlwmxC: "编辑",
  JjwFfqHG: "警告",
  nlZBTfzL: "确认删除这条数据？",
  bvwOSeoJ: "删除成功！",
  HJYhipnp: "删除",
  rnyigssw: "昵称",
  SPsRnpyN: "手机号",
  morEPEyc: "新增",
  wXpnewYo: "编辑",
  VjwnJLPY: "新建",
  NfOSPWDa: "更新成功！",
  JANFdKFM: "创建成功！",
  jwGPaPNq: "不能为空",
  iricpuxB: "不能为空",
  UdKeETRS: "不能为空",
  AnDwfuuT: "手机号格式不正确",
  QFkffbad: "不能为空",
  EfwYKLsR: "邮箱格式不正确",
  Dashboard: "仪表盘",
  "OTA Management": "OTA管理",
  "Version Stats": "版本统计",
  "Device Info": "设备查询",
  "System Management": "系统管理",
  HciKVeLm: "设备型号",
  dPlTSvdD: "源版本",
  odOEtkpc: "目标版本",
  iqNKQpUY: "白名单",
  ilBWxpDY: "黑名单",
  EECJHiOb: "强升",
  tnukMXhH: "大小",
  RpoOXBQc: "设备型号(模糊匹配)",
  gKJjLuzZ: "只能输入数字、字母和逗号",
  GhIekrta: "原版本",
  nyQpZIRx: "星号*表示允许所有设备升级（黑名单优先），多个设备请以英文逗号分隔",
  pCdmENWl: "即便白名单为*，黑名单内设备依然不会升级",
  KItVPsDB: "强制升级",
  SibonDPU: "开启强制升级，那么设备端不需要用户确认，会自动升级并重启设备",
  qldILawE: "设备ID",
  uKVLxprY: "型号",
  vbywrUeP: "版本",
  pVGgKiCk: "安装日期",
  msetuSsf: "登录时间",
  sopqNxZx: "设备型号/IMEI/ICCID(精确匹配)",
  nkvLiIsd: "共",
  UhhJtmtn: "条数据",
  DGqCBsXF: "总计",
  YwyNhmIM: "数量",
  SvPwzqNq: "装车量",
  XumaHpZN: "设备型号(精确匹配)",
  kokkZHDl: "对不起，你访问的页面不存在。",
  zxeoPXnd: "首页",
  MVGSoqNu: "重置链接不正确，请检查。",
  tbBLCeGa: "两次密码不一致",
  SNLzwHEJ: "密码重置成功",
  ptQlibOB: "FOTA-ADMIN重置密码",
  AlLNrWWQ: "设置你的新密码",
  YOVwbIUF: "重复密码",
  kRlUmMSZ: "确认",
  SgycTwuo: "返回登录",
  XBJLLdXO: "无效的邮箱格式！",
  KGoWuyii: "邮件已发送，请到邮箱查看。",
  VLsbvhOs: "请输入验证码",
  VKRphndB: "验证码",
  mDBzTAPP: "忘记密码？",
  wjKXWQgp: "重置密码",
  zenwQGuP: "请输入邮箱",
  dashqAmN: "发送验证邮件",
  cCmFugej: "刷新",
  YNGDGAcJ: "关闭",
  XMZaTVqf: "关闭其他",
};
